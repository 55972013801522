import React, {useState, useContext, useEffect,Fragment} from 'react';
import { emaillist } from '../data/data';
import CsvDownload from 'react-json-to-csv';
import { Store } from '../stores/Store';
import TopBar from '../components/TopBar';
const Emaillist = () => {
    let context = useContext(Store)
    let [emaildata, setEmailData] = useState(null);
    const getEmaillist = async () => {
        await emaillist(context).then(responce=>{
            setEmailData(responce.data);
        });
    }
    useEffect(()=>{
        if(!emaildata){
            getEmaillist();
        }
    }, [emaildata]);
    return (emaildata)?(
        <div className="dashboard">
            <div className="center">
                    <h3>Users and Download</h3>
            </div>
            <div className="center emailbutton">
                <CsvDownload data={emaildata} />
            </div>
            <div className="center">
                <div className="box">
                    <table>
                        <thead>
                            <td>#</td>
                            <td>
                                Name
                            </td>
                            <td>
                                Email
                            </td>
                        </thead>
                        {emaildata.map((item, i)=><tr>
                            <td>{i+1}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                        </tr>)}
                    </table>
                </div>
            </div>
            <div className="center emailbutton">
                <CsvDownload data={emaildata} />
            </div>
        </div>
    ):<p>Loading</p>
}
export default Emaillist;
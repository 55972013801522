import React, {useContext} from "react";
import { Store } from "../stores/Store";
import { Link } from "react-router-dom";
const BottomBar = () => {
    let context = useContext(Store);
    return (
        <div className="topbar">
            <div className="center">
                <div className="col1">
                    <img src='/images/logo.png' className='logo' />
                </div>
                <div className="col9">
                    <div className="nav">
                        <Link to="/">Stats</Link>
                        <Link to="/emaillist">Mail List</Link>
                        <Link to="/" onClick={context.reset}>Logout</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BottomBar;
import React, {Fragment, useState, useContext, useEffect} from 'react';
import TopBar from '../components/TopBar';
import {emaillist, stats as getstats} from '../data/data';
import { Store } from '../stores/Store';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer} from 'recharts';

const Dashboard = () => {
    let context = useContext(Store);
    let [stats, setStats] = useState(null); 
    let [contentviews, setContentViews] = useState(null);
    let [subscribers, setSubscribers] = useState(null); 
    let [users, setUsers] = useState(null); 
    const round = (sentnumber) => {
        return Math.round(sentnumber * 10) / 10
    }
    useEffect(()=>{
        if(!stats){
            getStats();
        }else if(stats){
            
        }
    }, [stats]);
    
    const getStats = async () => {
        await getstats(context).then(responce=>{
            setStats(responce.data.data);
            setSubscribers(responce.data.data.newsubscriberssbydate);
            setUsers(responce.data.data.newusersbydate);
            setContentViews(responce.data.data.contentviewsbydate);
        });
    }
    return (stats)?(
            <div className="dashboard">
                <div className="center">
                    <h3>Users</h3>
                </div>
                <div className="center">
                    <div className="col5">
                        <div className='row'>
                            <div className="col5">
                                <div className="box statbox">
                                    <div className='title'>Total Users:</div>
                                    <div className='score' >{round(stats.totalusers)}</div>
                                </div>
                            </div>
                            <div className="col5">
                                <div className="box statbox">
                                    <span className='title'>Users Last 30 days:</span><br/>
                                    <span className='score' >{round(stats.totaluserslast30days)}</span>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className="col5">
                                <div className="box statbox">
                                    <span className='title'>Users Last 14 days:</span><br/>
                                    <span className='score' >{round(stats.totaluserslast14days)}</span>
                                </div>
                            </div>
                            <div className="col5">
                                <div className="box statbox">
                                    <span className='title'>Users Last 7 days:</span><br/>
                                    <span className='score' >{round(stats.totaluserslast7days)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col5">
                        <div className="col10">
                            <div className="box statbox">
                                <span className='title'>Users Yesterday:</span><br/>
                                <span className='score' >{round(stats.totalusersyesterday)}</span>
                            </div>
                        </div>
                        <div className="col10">
                            <div className="box statbox">
                                <span className='title'>Users Today:</span><br/>
                                <span className='score' >{round(stats.totaluserstoday)}</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="center">
                        <h3>Subscribers</h3>
                </div>
                <div className="center">
                    <div className="col5">
                        <div className="col10">
                            <div className="box statbox">
                                <span className='title'>Subscribers Yesterday:</span><br/>
                                <span className='score' >{round(stats.totalsubscriptionsyesterday)}</span>
                            </div>
                        </div>
                        <div className="col10">
                            <div className="box statbox">
                                <span className='title'>Subscribers Today:</span><br/>
                                <span className='score' >{round(stats.totalsubscriptionstoday)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col5">
                        <div className="row">
                            <div className="col5">
                                <div className="box statbox">
                                    <span className='title'>Total Subscribers:</span><br/>
                                    <span className='score' >{round(stats.totalsubscriptions)}</span>
                                </div>
                            </div>
                            <div className="col5">
                                <div className="box statbox">
                                    <span className='title'>Subscribers Last 30 days:</span><br/>
                                    <span className='score' >{round(stats.totalsubscriptionslast30days)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col5">
                                <div className="box statbox">
                                    <span className='title'>Subscribers Last 14 days:</span><br/>
                                    <span className='score' >{round(stats.totalsubscriptionslast14days)}</span>
                                </div>
                            </div>
                            <div className="col5">
                                <div className="box statbox">
                                    <span className='title'>Subscribers Last 7 days:</span><br/>
                                    <span className='score' >{round(stats.totalsubscriptionslast7days)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="center">
                        <h3>Active Users</h3>
                </div>
                <div className="center">
                    <div className="col2">
                        <div className="box statbox">
                            <span className='title'>Re-engagment %:</span><br/>
                            <span className='score' >{round((stats.activeusers30/stats.totalusers)*100)}</span>
                        </div>
                    </div>
                    <div className="col2">
                        <div className="box statbox">
                            <span className='title'>Active users 30 days:</span><br/>
                            <span className='score' >{round(stats.activeusers30)}</span>
                        </div>
                    </div>
                    <div className="col2">
                        <div className="box statbox">
                            <span className='title'>Active users 14 days:</span><br/>
                            <span className='score' >{round(stats.activeusers14)}</span>
                        </div>
                    </div>
                    <div className="col2">
                        <div className="box statbox">
                            <span className='title'>Active users 7 days:</span><br/>
                            <span className='score' >{round(stats.activeusers7)}</span>
                        </div>
                    </div>
                    <div className="col2">
                        <div className="box statbox">
                            <span className='title'>Active users today:</span><br/>
                            <span className='score' >{round(stats.activeuserstoday)}</span>
                        </div>
                    </div>
                </div>
                <div className="center">
                    <div className="col10">
                    <div className="box">
                        <h3>New Subscriptions</h3>
                        {(subscribers)?<ResponsiveContainer width="100%" aspect={3.0/1.0}><AreaChart width={730} height={250} data={subscribers}
                        margin={{ top: 5, right: 0, left: -30, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="day" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="value" stroke="#8884d8" />
                        </AreaChart></ResponsiveContainer>:<p>Loading</p>}
                    </div>
                    </div></div><div className="center">
                    <div className="col5">
                    <div className="box">
                        <h3>New Users</h3>
                    {(subscribers)?<ResponsiveContainer width="100%" aspect={3.0/1.5}><AreaChart data={users}
                    margin={{ top: 5, right: 0, left: -30, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="value" stroke="#8884d8" />
                    </AreaChart></ResponsiveContainer>:<p>Loading</p>}
                    </div>
                    </div>
                
                    <div className="col5">
                    <div className="box">
                        <h3>Content Views</h3>
                    {(subscribers)?<ResponsiveContainer width="100%" aspect={3.0/1.5}><AreaChart data={contentviews}
                    margin={{ top: 5, right: 0, left: -30, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="value" stroke="#8884d8" />
                    </AreaChart></ResponsiveContainer>:<p>Loading</p>}
                    </div>
                    </div>
                </div>
            </div>
    ):(<p>Loading</p>);
}
export default Dashboard;
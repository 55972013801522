import React, {useState, useContext, useEffect} from 'react';
import { login } from '../data/auth';
import { Store } from '../stores/Store';
const userlogin =  async (email, password, context) =>{
    login(email, password, context).then(async (response)=>{ 
        if(response.status === 401)
        {
            context.notify({
                type:'error',
                message:'There was an issue authenticating your username or password!'
            });
        }
        if(response.status === 500)
        {
            context.notify({
                type:'error',
                message:'We are having issues with our servers - Please try later'
            });
        }
        if(response.status === 200)
        {
            context.notify({
                type:'success',
                message:'Logging in',
            });
            
        }
    });
}
const Login = () => {
    const context = useContext(Store);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const submit = () =>{
        userlogin(email, password, context)
    }
    return (
        <div className='center'>
            <div className='box col5'>
                <div className='center morespace'>
                    <img className='col3' src='/images/logo.png' />
                </div>
                <input placeholder='Email' className='form-control' onChange={(e)=>{setEmail(e.target.value)}} value={email} /> 
                <input placeholder='Password' type='password' className='form-control' onChange={(e)=>{setPassword(e.target.value)}} value={password} />
                <button className='btn success' onClick={submit}>Submit</button>
            </div>
        </div>
    );
}
export default Login;
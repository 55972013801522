import axios from 'axios';
const auth = process.env.REACT_APP_AUTH;

export const login = async (email, password, context) => {
    try {
        const response = await axios.post(auth + '/user/login', {
            email: email,
            password: password
        });
        
        await context.update({ token: response.data.token });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const user = async (context) => {
    try {
        const response = await axios.get(auth + '/user', {
            headers: {
                Authorization: 'Bearer ' + context.token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
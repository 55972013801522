import axios from 'axios';
const auth = process.env.REACT_APP_AUTH;

export const stats = async (context) => {
    try {
        const response = await axios.get(auth + '/admin/stats', {
            headers: {
                Authorization: 'Bearer ' + context.token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const emaillist = async (context) => {
    try {
        const response = await axios.get(auth + '/admin/emaillist', {
            headers: {
                Authorization: 'Bearer ' + context.token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}